



































































































































import { Vue } from "vue-property-decorator";

export default class RedesScociales extends Vue {
  private win = window;
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
